import React, { useState, useEffect } from "react";
import SwapHorizontalCircleTwoToneIcon from '@mui/icons-material/SwapHorizontalCircle';
import { OutlinedInput } from "@mui/material";
import Container from '@mui/material/Container';
import Paper from "@mui/material/Paper";
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const REACT_APP_API_URL = "https://api.openweathermap.org/data/2.5";
const REACT_APP_API_KEY = "dc61c30e8aa53aaa486a04313f330ff1";
const REACT_APP_API_UNIT = "metric"; // Set to metric in order to get temperature in celsius instead of kelvin (options: standard, metric, imperial)

function App() {

  const [celsiusWeather, setCelsiusWeather] = useState();
  const [celsius, setCelsius] = useState(0);
  const [fahrenheit, setFahrenheit] = useState(0);
  const [location, setLocation] = useState("");
  const [weatherDescription, setWeatherDescription] = useState("");
  const [weatherIcon, setWeatherIcon] = useState("");
  const [permissionS, setPermissionS] = useState("");
  // const [loading, setLoading] = useState(false);


  useEffect(() => {
    function getPermissionStatus() {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          setPermissionS(permissionStatus.state)
          // console.log(`geolocation permission state is ${permissionStatus.state}`);

          permissionStatus.onchange = () => {
            setPermissionS(permissionStatus.state);
            // console.log(
            //   `geolocation permission status has changed to ${permissionStatus.state}`,
            // );
          };
        });
    }
    getPermissionStatus()
  }, []) // ==================== > Diese Funktion läuft immer beim Laden der Seite aufgrund des leeren []

  useEffect(() => {
    const fetchData = async () => {
      navigator.geolocation.getCurrentPosition(function (position) {
        if (permissionS === "granted") {
          fetch(`${REACT_APP_API_URL}/weather?lat=${position.coords.latitude}&lon=${position.coords.longitude}&units=${REACT_APP_API_UNIT}&appid=${REACT_APP_API_KEY}`)
            .then(result => result.json())
            .then(result => {
              setLocation(result.name)
              setCelsius((result.main.temp).toFixed(1));
              setCelsiusWeather((result.main.temp).toFixed(1));
              setFahrenheit(convertToFahrenheit(result.main.temp));
              setWeatherDescription(result.weather[0].description);
              setWeatherIcon(result.weather[0].icon);
            });
        }
      });
    }
    fetchData();
  }, [permissionS]) // ==================== > Dieses Funktion läuft nur wenn sich die Variable permissionS ändert aufgrund von [permissionS]


  function convertToFahrenheit(value) {
    return ((value * (9 / 5)) + 32).toFixed(1);
  }

  function convertToCelsius(value) {
    return ((value - 32) * (5 / 9)).toFixed(1);
  }

  function handleChange(name, value) {
    if (name === "fahrenheit") {
      setFahrenheit(value);
      setCelsius(convertToCelsius(value));
    } else if (name === "celsius") {
      setCelsius(value);
      setFahrenheit(convertToFahrenheit(value));
    }
  }


  return (
    <React.Fragment>
      <CssBaseline />

      <div className="page-wrapper">
        <Container className="page-container">

          <div className="aufenthaltsort">

            {/* Keine permission definiert */}
            {permissionS !== "granted" &&
              <p>Zum Anzeigen des aktuellen Wetters, bitte Ortung aktivieren</p>
            }

            {/* Permission definiert - LOADING location */}
            {permissionS === "granted" && location === "" &&
              <Box sx={{ display: 'flex', justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            }

            {/* Permission definiert - LOADING location abgeschlossen*/}
            {permissionS === "granted" && location !== "" &&
              <React.Fragment>
                <p>Ihr aktueller Aufenthaltsort: {location}</p>
                <p className="celsius">{celsiusWeather}°C</p>
                <img alt="weatherIcon" className="weatherIcons" src={`https://openweathermap.org/img/wn/${weatherIcon}.png`} />
                <p className="wetter">{weatherDescription}</p>
              </React.Fragment>
            }

           
            <img
              className="thermometer"
              src="https://cdn.pixabay.com/photo/2015/07/05/10/18/tree-832079_960_720.jpg"
            />
          </div>

          <Paper className="paper2" elevation={12} sx={{ maxWidth: '100%' }}>
            <p className="h1">Umrechner</p>
            <p className="h2">Fahrenheit</p>

            <OutlinedInput type="number" value={fahrenheit} name="fahrenheit" onChange={event => handleChange(event.target.name, event.target.value)} />
            <span> °F </span>

            <p className="arrowIcon" ><SwapHorizontalCircleTwoToneIcon fontSize="larger" strokeWidth={1.5} />
            </p><p className="h3">Celsius</p>

            <OutlinedInput value={celsius} className="inputCelsius" type="number" name="celsius" onChange={event => handleChange(event.target.name, event.target.value)} />
            <span> °C </span></Paper>

        </Container>
      </div>
      <p className="distribution">background by SVGBackgrounds.com</p>
    </React.Fragment>
  );
};

export default App;


